footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #868484ca;
}

.footer-text {
  font-size: small;
  text-align: center;
  flex: 1 1 auto;
}

.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly
}

.todo-form {
  width: 60%;
}

.todo-list {
  width: 75%;
  box-shadow: 0px 0px 9px 0px rgba(115, 81, 211, 0.1);
}

.todo-view {
}

.todo-edit {

}

.todo-item {
  box-shadow: 0px 0px 9px 0px rgba(115, 81, 211, 0.1);
}

.todo-item:hover {
  background-color: #f4f3ff;
}

.todo-label {
  font-size: medium;
  margin-right: 20%;
  margin-left: 3%;
  display: block;
}

.todo-view-btn {
  float: right; 
}

.isNotValid {
  color:#e38c12;
  font-weight: bolder;
  font-size: medium ;
  float: left;
  width: 20px;
}

.isValid {
  float: left;
  width: 20px;
}


.left{
  float: left;
  width: 320px;
}

.mid{
  float: left;
  width: 275px;
}

.sp{
  float: left;
}


.color-custom {
  background-color: #868484ca;
}

.nav-link{
  background-color: #afa69eca !important;
  color: #04080b !important;
  
}

.navbar-brand{
  color: #04080b !important;
}

.navbarLogo {
  position: relative;
  height: 30px;
  right: 15px;
}


/* Ensure the collapse happens on smaller screens */
.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.5); /* Custom border for hamburger button */
}

/* Optional: Adjust the style of the menu */
.navbar-collapse {
  text-align: left; /* Ensures items align properly in the collapsed view */
}

@media (max-width: 992px) {
  .navbar-collapse {
      justify-content: space-between;
  }
}

.signupNav {
  border: 2px solid #555555;
}

.signupItem {
  width: auto;
  
}



.propsName {
  display:inline-block; width: 250px; color: #0056b3;

}

.button-span {
  display: flow-root;
  width: auto;
  float:left;
}

.propsPackage {

  font-size: small;
}
.propsLastLogin {

  font-size: small;
}

.propsContact {
  display:inline-block; width: 250px; font-family: 'Roboto', sans-serif; font-size: 14px;

}

.propsContactName {
  display:inline-block; width: 250px; font-family: 'Roboto', sans-serif; font-size: 15px; font-weight: bold;

}

.propsPhone {
  display:inline-block; width: 250px; font-family: 'Roboto', sans-serif; font-size: 14px;

}

.propsSalesPerson {
  display:inline-block;  font-family: 'Roboto', sans-serif; font-size: 13px;

}

.propsExpDate {
    display: inline-block;
    font-size: small;
    float: right;

}
.propsCreatedDate {
  display: inline-block;
  float:right;
  font-size: small;

}
.actionsButtons {
  margin-inline: 78%;
  
}

.tablehead {
  width: 70%;
}

#keywords thead {
  cursor: pointer;
  background: #c9dff0;
}
#keywords thead tr th { 
  font-weight: bold;
  padding: 12px 30px;
  padding-left: 42px;
}
#keywords thead tr th span { 
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

#keywords thead tr th.headerSortUp, #keywords thead tr th.headerSortDown {
  background: #acc8dd;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #e38c12;
  border-bottom: 16px solid rgb(97, 97, 100);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.toastMessage {
  width: 60%;
}

.extendDate {
  display :none;
}

.extendMess {
  display :none;
}

.extendFailMess {
  display :none;
}

.markMess {
  display :none;
}

.markFailMess {
  display :none;
}

.validateMess {
  display :none;
}

.validateFailMess {
  display :none;
}

.blockMess {
  display :none;
}

#confirmValidate {
  display :none;
}

#confirmMark {
  display :none;
}

#confirmExtend {
  display :none;
}

#confirmBlock {
  display :none;
}


.popup {
  width: 80%;
  padding: 15px;
  left: 0;
  margin-left: 5%;
  border: 1px solid rgb(1,82,73);
  border-radius: 10px;
  color: rgb(1,82,73);
  background: white;
  position: absolute;
  top: 15%;
  box-shadow: 5px 5px 5px #000;
  z-index: 10001;
  font-weight: 700;
  text-align: center;
}

.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.85);
  z-index: 10000;
  display :none;
}

@media (min-width: 768px) {
  .popup {
    width: 66.66666666%;
    margin-left: 16.666666%;
  }
}
@media (min-width: 992px) {
  .popup {
    width: 80%;
    margin-left: 25%;
  }
}
@media (min-width: 1200px) {
  .popup {
    width: 33.33333%;
    margin-left: 33.33333%;
  }
}

.dialog-btn {
  background-color:#44B78B;
  color: white;
  font-weight: 700;
  border: 1px solid #44B78B;
  border-radius: 10px;
  height: 30px;
  width: 30%;
}
.dialog-btn:hover {
  background-color:#015249;
  cursor: pointer;
}
.isInactive {
    border: 1px solid;
    padding: 2px;
    font-size: smaller;
}

#mainTitle{
  font-family: "Helvetica";
}

#cancel {
  background-color: rgb(218, 80, 80);
}

#confirm {
  background-color: rgb(93, 173, 106);
}

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

#myBtn:hover {
  background-color: #555;
}

#filterOptions {
  display:flex;
}

/* General styling for filters */
.filter-container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 20px;
}

.filter-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Checkbox and label positioning */
.cb-btn {
  margin-right: 5px;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .filter-container {
      justify-content: flex-start;
      flex-direction: column;
  }

  .filter-item {
      margin-bottom: 10px;
      width: 100%;
  }
}

/* Media queries for larger screens */
@media (min-width: 769px) and (max-width: 1024px) {
  .filter-container {
      justify-content: flex-start;
      flex-direction: row;
      gap: 10px;
  }

  .filter-item {
      flex-basis: 30%;
  }
}

.cb-btn {
  display: none;
}

.cb-btn + label {
  display: inline;
  border: 1px solid #999;
  border-radius: 3px;
  cursor: pointer;
  padding: 2px 0.5em 2px 2.5em;
  position: relative;
}

.cb-btn:not(:checked + label):hover {
  border-color: #a00;
  color: #a00;
  transition: all .2s ease-in-out;
}

.cb-btn + label::before {
  background-color: #999;
  border-radius: 3px 0 0 3px;
  bottom: 0;
  content: "> ";
  display: block;
  left: 0;
  line-height: 1.4em;
  position: absolute;
  text-align: center;
  top: 0;
  transition: all .2s ease-in-out;
  width: 2em;
}

.cb-btn:not(:checked) + label:hover::before {
  background-color: #a00;
  color: #fff;
}

.cb-btn:checked + label {
  border: 1px solid green;
  border-radius: 5px;
  color: green;
  cursor: pointer;
  padding: 5px 0.5em 5px 2.5em;
  position: relative;
}

.cb-btn:checked + label::before {
  background-color: green;
  color: #fff;
}






.form-link {
  display: block;
  margin-top: 10px;
  font-weight: 600;
  color: #0a0a0a;
  text-decoration: none;
}

.form-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.CS-clickable-row:hover {
  background-color: #f1f1f1;
}

.CS-clickable-row tbody td {
  flex-grow: 1;
}

.btn-assign-cs:hover {
  background-color: #565c62; /* Change background color on hover */
  color: white; /* Change text color on hover */
}



html,
body {
  height: 100%;
  overflow-y: auto;
}



/* Ensure the table is wrapped in a responsive scrollable container */
.table-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 20px; /* Prevent footer from overlapping scrollbar */
  margin-bottom: 20px; /* Add space at the bottom for better visibility */
}

/* Scrollable container */
.table-container {
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
}

/* Scroll Arrow Styling */
.scroll-arrow {
  position: fixed;
  top: 50%; /* Center the arrow vertically */
  transform: translateY(-50%); /* Ensure the arrow is centered even when resized */
  font-size: 18px; /* Consistent font size */
  background-color: #cacdcf;
  color: black;
  font-weight:bold;
  width: 40px; /* Set consistent width */
  height: 40px; /* Set consistent height */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1000;
  opacity: 0.8;
}

/* Scroll left arrow */
.scroll-arrow.left {
  left: 5px;
}

/* Scroll right arrow */
.scroll-arrow.right {
  right: 10px;
}

/* Hover effect for the arrows */
.scroll-arrow:hover {
  background-color: #0056b3;
}

.task-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Header Styling */
.task-table thead th {
  background-color: #eeb051; /* Header background color */
  color: rgb(12, 12, 12); /* Text color */
  padding: 8px 10px; /* Reduce padding */
  font-size: 15px; /* Smaller font size */
  text-align: left;
  white-space: nowrap;
  border: 1px solid #ddd; /* Add border to headers */
}

/* Row and Cell Styling */
.task-table tbody td {
  padding: 10px 15px; /* Padding for table cells */
  font-size: 13px; /* Font size for table cells */
  font-weight: 500;
  background-color: #ffffff;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  white-space: nowrap;
}

.task-table td.task-actions {
  text-align: left; /* Align content to the left */
  padding-left: 10px; /* Optional: Add some padding for spacing */
}

/* Hover effect and cursor */
.task-table tbody tr:hover td{
  background-color: #b8b3a6; /* Light blue hover effect */
  cursor: pointer; /* Palm symbol (pointer) when hovering over a row */
}

.task-table thead th:hover {
  background-color: #a9b2bb; /* Darker blue on hover */
  transition: background-color 0.2s ease; /* Smooth transition effect */
}

/* Zebra striping */
.task-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}


/* Responsive Design */
@media (max-width: 768px) {
  /* Make table horizontally scrollable on smaller screens */
  .table-wrapper {
      overflow-x: auto;
  }

  /* Reduce padding and font size for better visibility */
  .task-table th, .task-table td {
      padding: 8px 10px;
      font-size: 12px;
  }
}

@media (max-width: 576px) {
  .task-table th, .task-table td {
      padding: 6px;
      font-size: 11px;
  }

  /* Ensure the table can scroll horizontally */
  .task-table {
      width: auto; /* Allows the table to fit into the container */
  }

  .table-wrapper {
      padding-bottom: 40px; /* Extra padding to avoid footer overlap */
  }
}

/* Info Container */
.info-container {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto; /* Prevent it from growing too large */
  min-width: 600px;
  max-width: 900px; /* Restrict overall width */
  margin-left: 0; /* Align to the left */
  /* Existing styles */
  gap: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Info Row */
.info-row {
  display: flex; /* Flex layout for rows */
  border-bottom: 1px solid #ddd; /* Divider between rows */
}

.info-row:last-child {
  border-bottom: none; /* No border for the last row */
}

/* Info Cell (Shared Styling) */
.info-cell {
  padding: 10px; /* Padding for readability */
  flex: 1; /* Allow equal flex distribution */
  border-right: 1px solid #ddd; /* Divider between cells */
  overflow: hidden; /* Prevent any overflow issues */
}

.info-cell:last-child {
  border-right: none; /* Remove right border for the last cell */
}

/* Key Cell */
.info-cell.key {
  font-weight: bold;
  background-color: #eeb051; /* Orange background for keys */
  color: black; /* Black text for keys */
  width: 30%; /* Fixed width for keys */
  text-align: left; /* Align text to the left */
}

/* Value Cell */
.info-cell.value {
  flex: 3; /* Allocate more space for values compared to keys */
  max-height: 150px; /* Increase height to show more content */
  overflow-y: auto; /* Enable vertical scrolling for overflowing content */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  background-color: #fff; /* White background for values */
  color: #333; /* Darker text color for better readability */
  word-break: break-word; /* Break long words to fit */
  line-height: 1.5; /* Slightly increase line spacing for readability */
  padding: 12px; /* Add a little more padding for spacing */
}

/* Alternate Row Backgrounds */
.info-row:nth-child(even) {
  background-color: #f7f7f7; /* Light gray background for alternate rows */
}

/* Customer Info Container */
.customer-info {
  width: 650px; /* Fixed width */
  flex-shrink: 0; /* Prevent it from shrinking */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Remove margin-left: auto; */
}
/* Heading Style */
.customer-info h3 {
  margin-bottom: 20px; /* Space below heading */
  font-size: 22px; /* Adjust font size */
  color: #333; /* Darker text for readability */
  border-bottom: 1px solid #ddd; /* Subtle border below heading */
  padding-bottom: 10px; /* Space below the heading text */
}

/* Subscription Details */
.subscription-details {
  margin-bottom: 15px; /* Space between subscription blocks */
}

/* Key-Value Pairs */
.cust.pair {
  display: flex; /* Flex layout for alignment */
  justify-content: space-between; /* Align key and value on opposite sides */
  margin-bottom: 10px; /* Add space between pairs */
}

/* Key Style */
.cust.pair .key {
  font-weight: bold; /* Bold for keys */
  color: #555; /* Softer text color */
  width: 50%; /* Allocate space for the key */
  text-align: left; /* Align text to the left */
}

/* Value Style */
.cust.pair .value {
  width: 50%; /* Allocate space for the value */
  color: #333; /* Darker text for values */
  text-align: right; /* Align values to the right */
}

/* Button Container */
.customer-info .button-container {
  display: flex; /* Flex layout for buttons */
  justify-content: flex-start; /* Align buttons to the left */
  gap: 10px; /* Add space between buttons */
  margin-top: 20px; /* Space above buttons */
}

/* Buttons */
.customer-info .btn-primary {
  background-color: #6cc8a1; /* Green background for primary button */
  color: black; /* Black text */
  font-weight: bold; /* Bold text */
  padding: 10px 15px; /* Padding for better click area */
  border-radius: 5px; /* Rounded corners */
  border: none; /* Remove border */
  cursor: pointer; /* Pointer cursor */
}

.customer-info .btn-danger {
  background-color: #f26565; /* Red background for danger button */
  color: black; /* Black text */
  font-weight: bold; /* Bold text */
  padding: 10px 15px; /* Padding for better click area */
  border-radius: 5px; /* Rounded corners */
  border: none; /* Remove border */
  cursor: pointer; /* Pointer cursor */
}

.customer-info .btn-primary:hover,
.customer-info .btn-danger:hover {
  opacity: 0.9; /* Slightly reduce opacity on hover */
}
/* Main Container */
.main-container {
  display: flex; /* Enable flexbox for horizontal alignment */
  justify-content: flex-start; /* Align items to the start (left) */
  align-items: flex-start; /* Align items at the top */
  gap: 50px; /* Space between info-container and customer-info */
  margin: 20px; /* Add margin */
  max-width: 100%; /* Use full available width */
  min-width: 800px; /* Ensure minimum width to prevent overlap */
  overflow-x: auto; /* Enable horizontal scrolling for browser */
}


/* Common Button Styles */
button {
  font-size: 14px; /* Standardize font size */
  font-weight: bold; /* Bold text for emphasis */
  margin: 6px 4px; /* Uniform spacing */
  padding: 10px 15px; /* Spacious padding for better click area */
  border-radius: 6px; /* Smooth, modern rounded corners */
  cursor: pointer; /* Pointer cursor for interaction */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: none; /* Remove default button border */
  transition: all 0.3s ease; /* Smooth transitions for hover effects */
  color: black; /* Default white text for contrast */
}

/* Hover Effects */
button:hover {
  opacity: 0.9; /* Subtle dimming on hover */
  transform: translateY(-2px); /* Lift effect */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}

/* Time Zone Change Button */
.timeZoneChangeButton {
  background-color: #77a0b5; /* Teal background */
}

/* LCR Button */
.lcrButton {
  background-color: #c081dd; /* Purple shade */
}

/* Invoice Button */
.invoiceButton {
  background-color: #e67e7e; /* Soft red */
}

/* Renew Invoice Button */
.renewinvoiceButton {
  background-color: #3b63e7; /* Bright blue */
}

/* Plan Change Button */
.planChangeButton {
  background-color: #dda381; /* Peach */
}

/* Extend Trial Button */
.extendTrialButton {
  background-color: #9481dd; /* Lavender */
}

/* Owner Change Button */
.ownerChangeButton {
  background-color: #95ad95; /* Sage green */
}

/* CS Change Button */
.csChangeButton {
  background-color: #dd9191; /* Warm red */
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Consistent spacing between buttons */
  align-items: center; /* Vertical alignment */
}

/* Active State */
button:active {
  transform: translateY(1px); /* Subtle press effect */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Smaller shadow on click */
}

/* Disabled State */
button:disabled {
  background-color: #ccc; /* Light gray for disabled buttons */
  color: #666; /* Darker gray text */
  cursor: not-allowed; /* Prevent interaction */
  opacity: 0.7; /* Less prominent */
}
